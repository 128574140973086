import React, { ReactElement } from 'react'
import Head from 'next/head'
import { ProductWithQuantity } from '@mindfulchefuk/query'
import { DEFAULT_IMAGE } from '@mindfulchefuk/constants'
import { ProductModalWithCTAs } from '@mindfulchefuk/features/Products/components/ProductModal/ProductModalWithCTAs'

interface SchemaProps {
  title: string
  imageUrls: string[] | string
  description: string
  sku: string
  price: string
  inStock: boolean
}

const mapProductToSchema = ({
  title,
  imageUrls,
  description,
  sku,
  price,
  inStock,
}: SchemaProps) => ({
  '@context': 'https://schema.org/',
  '@type': 'Product',
  name: title,
  image: imageUrls,
  description,
  sku,
  offers: {
    '@type': 'Offer',
    availability: `https://schema.org/${inStock ? 'InStock' : 'OutOfStock'}`,
    price,
    priceCurrency: 'GBP',
    url: window.location.href,
  },
  brand: {
    '@type': 'Brand',
    name: 'Mindful Chef',
  },
})

type Props = {
  product: ProductWithQuantity
  isDisabled?: boolean
  onClose: () => void
  disableAdding?: boolean
}

export const ProductPage = ({
  product,
  isDisabled,
  onClose,
  disableAdding = false,
}: Props): ReactElement => {
  const schemaData = mapProductToSchema({
    title: product.name,
    imageUrls: product.images?.length
      ? product.images.map((img) => img.url)
      : product.imageUrl || DEFAULT_IMAGE,
    description: product.description,
    sku: product.sku,
    price: product.price?.toFixed(2),
    inStock: product.inStock,
  })

  return (
    <>
      <Head>
        <script
          className="structured-product-data"
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
      </Head>

      <ProductModalWithCTAs
        onClose={onClose}
        disableAdding={disableAdding}
        product={product}
        isDisabled={isDisabled}
      />
    </>
  )
}
