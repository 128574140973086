import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { ProductType } from '@mindfulchefuk/query'
import { ProductType as ProductTypeAnalytics } from '@mindfulchefuk/analytics'
import { instrumentProductViewEvent } from '@mindfulchefuk/utils/analytics/amplitude/instrument'

type UseInstrumentProductsViewedProps = {
  totalNumberofProductsInPreferences: number
  experimentGroup?: string
}

export const useInstrumentProductsViewed = ({
  totalNumberofProductsInPreferences,
  experimentGroup,
}: UseInstrumentProductsViewedProps) => {
  const router = useRouter()
  const productType = router.query.page as ProductType

  useEffect(() => {
    instrumentProductViewEvent({
      productCategory: productType as ProductTypeAnalytics,
      totalNumberofProductsInPreferences,
      experimentGroup,
    })
    // Ignoring changes to productCategory as we only want
    // this to fire once when the page loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
