import routesConfig from '@mindfulchefuk/config/routesConfig'
import {
  shopProductTypeConfig,
  productTypeConfig,
} from '@mindfulchefuk/features/Products/productTypeConfig'
import { ProductType } from '@mindfulchefuk/query'
import { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

export interface ProductNavigationItem {
  id: string
  title: string
  image: string
  href: LinkProps['href']
  isActive: boolean
}

export const useProductNavigationItems = (
  type: 'onboarding' | 'my-account'
): ProductNavigationItem[] => {
  const router = useRouter()

  return useMemo(() => {
    const page = router.query.page as ProductType
    const deliveryDate = router.query.deliveryDate as string

    const config =
      type === 'onboarding' ? shopProductTypeConfig : productTypeConfig

    return Object.values(config).map((category) => {
      const onboardingHref = routesConfig.onboardingShop.pathname(category.id)
      const myAccountHref = deliveryDate
        ? {
            pathname: routesConfig.delivery.pathname(deliveryDate),
            query: { page: category.id },
          }
        : ''

      return {
        id: category.id,
        href: type === 'onboarding' ? onboardingHref : myAccountHref,
        title: category.name,
        image: category.image,
        isActive: category.id === page,
      }
    })
  }, [router.query.deliveryDate, router.query.page, type])
}
