import { useEffect } from 'react'
import {
  analyticsEvents,
  ProductType as ProductTypeAnalytics,
} from '@mindfulchefuk/analytics'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'
import { useRouter } from 'next/router'
import { ProductType } from '@mindfulchefuk/query'

export const useInstrumentReadyToGoViewed = () => {
  const router = useRouter()
  const productType = router.query.page as ProductType
  useEffect(() => {
    if (productType) {
      const { name, data } = analyticsEvents.readyToGoViewed(
        productType as ProductTypeAnalytics
      )

      instrument(name, { ...data })
    }
  }, [productType])
}
