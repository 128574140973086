import React, { FunctionComponent, ReactElement } from 'react'
import styled from '@emotion/styled'
import {
  Box,
  Flex,
  Image,
  minWidths,
  PriceText,
  Text,
  theme,
} from '@mindfulchefuk/design-system'
import { ProductWithQuantity } from '@mindfulchefuk/query'
import { UpdateProductQuantityFn } from '@mindfulchefuk/features/Products/interfaces'

import { openModal } from '@mindfulchefuk/utils/modalUtils'

import { OldProductCTAs } from '@mindfulchefuk/features/Products/components/ProductCard/OldProductCTAs'
import { ChilliLevel } from '@mindfulchefuk/features/Onboarding/components/RecipeStats/ChilliLevel'
import { isSingleCheckoutJourney } from '@mindfulchefuk/utils/isSingleCheckout'
import { useProductCart } from '@mindfulchefuk/features/Cart/components/CartDrawer/hooks/useProductCart'
import { ProductCTAs } from '@mindfulchefuk/features/Products/components/ProductCard/ProductCTAs'
import { useDeliveryDate } from '@mindfulchefuk/features/Delivery/hooks/useDeliveryDate'

const ProductCardFlex = styled(Flex)`
  transition: transform ease-in-out 0.1s;

  &:hover {
    box-shadow: ${theme.shadows.standard};
    transform: scale(1.01);
  }
`

export type ProductCardProps = {
  disabled?: boolean
  product: ProductWithQuantity
  onAdd: UpdateProductQuantityFn
  onIncrement: UpdateProductQuantityFn
  onDecrement: UpdateProductQuantityFn
  onOpenDetails?: (product: ProductWithQuantity) => void
}

export const ProductCard: FunctionComponent<ProductCardProps> = ({
  disabled,
  product,
  onOpenDetails,
  onAdd,
  onIncrement,
  onDecrement,
}): ReactElement => {
  const handleOpenModal = () => {
    openModal('pid', product.id)
    onOpenDetails?.(product)
  }
  const isDiscounted = !!product.priceBeforeDiscount

  const deliveryDate = useDeliveryDate()
  const isSingleCheckoutEnabled = isSingleCheckoutJourney(deliveryDate)

  const { productsInCart } = useProductCart()

  const productQuantity = productsInCart.find(
    (cartProduct) => cartProduct.id === product.id
  )?.quantity

  const borderColor = productQuantity ? 'aubergine' : 'pepper-200'
  const borderWidth = productQuantity !== 0 ? 2 : 1

  return (
    <ProductCardFlex
      as="article"
      direction="column"
      position="relative"
      borderColor={borderColor}
      borderWidth={borderWidth}
      cursor="pointer"
      data-testid="product-card"
      height="100%"
      justify="space-between"
      onClick={handleOpenModal}
    >
      <Box as="header" overflow="hidden">
        <Image
          loading="lazy"
          src={product.imageUrl}
          alt=""
          width={500}
          height={500}
          sources={[
            {
              media: minWidths.md,
              width: 269,
              height: 269,
            },
          ]}
        />

        <Text as="h3" variant="body-md" color="pepper" p={10} minHeight={56}>
          {product.name}
        </Text>
      </Box>
      <Flex
        as="section"
        position="relative"
        direction="column"
        justify="space-between"
        px={12}
        pb={16}
        z={2}
        onClick={(e) => e.stopPropagation()}
      >
        <ChilliLevel spicinessLevel={product.spicinessLevel} />
        <Flex justify="space-between" align="center">
          <Flex direction={{ base: 'column', md: 'row' }}>
            {isDiscounted && (
              <PriceText
                as="span"
                color="tomato-550"
                variant={{ base: 'body-md', md: 'body-lg' }}
                isDiscounted
                price={product.priceBeforeDiscount}
                mr={8}
              />
            )}

            <PriceText
              as="span"
              color={isDiscounted ? 'tomato' : 'aubergine'}
              variant={{ base: 'body-md-500', md: 'body-lg-500' }}
              price={product.price}
            />
          </Flex>

          {!isSingleCheckoutEnabled ? (
            <OldProductCTAs
              product={product}
              onAdd={onAdd}
              onIncrement={onIncrement}
              onDecrement={onDecrement}
              disabled={disabled}
              source="product page"
            />
          ) : (
            <ProductCTAs product={product} disabled={disabled} />
          )}
        </Flex>
      </Flex>
    </ProductCardFlex>
  )
}
