import React, { FunctionComponent } from 'react'
import Link, { LinkProps } from 'next/link'

import { Flex, minWidths, Text } from '@mindfulchefuk/design-system'
import {
  ProductNavigationActiveMarker,
  ProductNavigationImage,
  ProductNavigationListItem,
} from './ProductNavigation.styles'

const IMAGE_MAX_WIDTH = 265
const IMAGE_MAX_HEIGHT = 170

interface Props {
  isActive: boolean
  href: LinkProps['href']
  image: string
  title: string
  onClick: (title: string) => void
}

export const ProductNavigationItem: FunctionComponent<Props> = ({
  isActive,
  href,
  image,
  title,
  onClick,
}) => {
  const anchor = React.useRef(null)

  React.useEffect(() => {
    if (!isActive) return

    const timeout = setTimeout(() => {
      anchor.current?.scrollIntoView?.({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    }, 200)

    return () => clearTimeout(timeout)
  }, [isActive])

  return (
    <ProductNavigationListItem
      data-testid="product-navigation-item"
      ref={anchor}
    >
      <Link href={href} passHref>
        <Flex as="a" direction="column" onClick={() => onClick(title)}>
          <ProductNavigationImage
            resolve
            alt={title}
            src={image}
            width={IMAGE_MAX_WIDTH}
            height={IMAGE_MAX_HEIGHT}
            sources={[
              {
                width: IMAGE_MAX_WIDTH,
                height: IMAGE_MAX_HEIGHT,
                media: minWidths.md,
              },
            ]}
          />

          <Text
            variant={{ base: 'body-sm-500-caps', lg: 'body-xs-500-caps' }}
            textAlign="center"
            color="aubergine"
            pt={12}
            pb={14}
          >
            {title}
          </Text>
          {isActive && (
            <ProductNavigationActiveMarker
              initial={{ opacity: 0, x: 0 }}
              animate={{ opacity: 1, x: 0 }}
              layoutId="product-navigation-decal"
            />
          )}
        </Flex>
      </Link>
    </ProductNavigationListItem>
  )
}
