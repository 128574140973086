import { ModalMobileCTA } from '@mindfulchefuk/design-system'
import { ProductModal } from '@mindfulchefuk/features/Products/components/ProductModal/ProductModal'
import { ProductWithQuantity } from '@mindfulchefuk/query'
import React, { VFC } from 'react'
import { ProductCTAs } from '@mindfulchefuk/features/Products/components/ProductCard/ProductCTAs'
import { PageSource } from '@mindfulchefuk/analytics'

type Props = {
  product: ProductWithQuantity
  pageSource?: PageSource
  isDisabled?: boolean
  onClose: () => void
  disableAdding?: boolean
}

export const ProductModalWithCTAs: VFC<Props> = ({
  product,
  pageSource,
  onClose,
  isDisabled,
  disableAdding,
}) => {
  return (
    <ProductModal product={product} onClose={onClose}>
      <ModalMobileCTA mb={[0, 32]}>
        <ProductCTAs
          isLarge
          pageSource={pageSource}
          disableAdding={disableAdding}
          product={product}
          disabled={isDisabled}
        />
      </ModalMobileCTA>
    </ProductModal>
  )
}
