import React, { VoidFunctionComponent } from 'react'
import { Container, StickyBar } from '@mindfulchefuk/design-system'
import { ProductNavigationItem } from '@mindfulchefuk/features/Products/components/ProductNavigation/ProductNavigationItem'
import { useProductNavigationItems } from '@mindfulchefuk/features/Products/components/ProductNavigation/useProductNavigationItems'
import { ProductNavigationList } from './ProductNavigation.styles'

type Props = {
  type: 'onboarding' | 'my-account'
  onItemClick: (title: string) => void
}

const ProductNavigation: VoidFunctionComponent<Props> = ({
  onItemClick,
  type,
}) => {
  const navigationItems = useProductNavigationItems(type)

  return (
    <StickyBar
      top={[-32, -14]}
      bg="white"
      borderTopWidth={2}
      z={9}
      borderColor="white"
    >
      <Container>
        <ProductNavigationList>
          {navigationItems.map((item) => (
            <ProductNavigationItem
              key={item.id}
              isActive={item.isActive}
              href={item.href}
              image={item.image}
              title={item.title}
              onClick={() => onItemClick(item.title)}
            />
          ))}
        </ProductNavigationList>
      </Container>
    </StickyBar>
  )
}
export default ProductNavigation
