import React, { PropsWithChildren, VoidFunctionComponent } from 'react'

import { ProductType } from '@mindfulchefuk/analytics/src/types'
import {
  Button,
  ButtonProps,
  Flex,
  Icon,
  ResetButton,
  Text,
  TextProps,
  theme,
} from '@mindfulchefuk/design-system'
import { Roundel } from '@mindfulchefuk/design-system/Roundel/Roundel'
import { ProductWithQuantity } from '@mindfulchefuk/query'
import {
  addProduct,
  decrementProduct,
} from '@mindfulchefuk/features/Cart/productCartSlice'
import {
  instrumentProductAdded,
  instrumentProductRemoved,
} from '@mindfulchefuk/utils/analytics/amplitude/instrument'
import { useDispatch } from 'react-redux'
import { useProductCart } from '@mindfulchefuk/features/Cart/components/CartDrawer/hooks/useProductCart'
import { PageSource } from '@mindfulchefuk/analytics'

type ProductCTAProps = {
  product: ProductWithQuantity
  pageSource?: PageSource
  isLarge?: boolean
  disabled?: boolean
  disableAdding?: boolean
}

export const ProductCTAs: VoidFunctionComponent<ProductCTAProps> = ({
  product,
  pageSource = 'product page',
  disabled,
  isLarge,
  disableAdding = false,
}) => {
  const dispatch = useDispatch()
  const addText = isLarge ? 'Add to order' : 'Add'
  const buttonSize = isLarge ? 'medium' : 'small'
  const roundelSize = isLarge ? 48 : 32
  const buttonProps: Partial<ButtonProps> = isLarge
    ? { fullWidth: true, size: 'medium' }
    : { size: 'small' }

  const addProductClickHandler = () => {
    instrumentProductAdded({
      productId: product.id,
      productSku: product.sku,
      productName: product.name,
      productType: 'ready to go',
      productCategory: product.productType as ProductType,
      productPrice: product.price,
      productQuantity: 1,
      pageSource,
    })
    dispatch(addProduct(product))
  }

  const removeProductClickHandler = () => {
    instrumentProductRemoved({
      productId: product.id,
      productSku: product.sku,
      productName: product.name,
      productType: 'ready to go',
      productCategory: product.productType as ProductType,
      productPrice: product.price,
      productQuantity: 1,
      pageSource,
    })
    dispatch(decrementProduct(product))
  }

  const { productsInCart } = useProductCart()
  const existingProduct = productsInCart.find(
    (cartProduct) => product.id === cartProduct.id
  )
  const textQuantity = existingProduct
    ? existingProduct.quantity
    : product.quantity
  const textPropChildren = `${textQuantity} in your basket`

  const textProps: PropsWithChildren<TextProps> = isLarge
    ? {
        children: textPropChildren,
        color: 'aubergine',
        variant: 'body-xl-500-caps',
        mx: 20,
      }
    : {
        children: textQuantity,
        color: 'pepper',
        variant: 'body-lg',
        width: 32,
      }

  if (!existingProduct && product.inStock) {
    return (
      <Button
        variant="primary"
        size={buttonSize}
        type="button"
        data-testid="product-add-button"
        onClick={addProductClickHandler}
        disabled={!product.inStock || disableAdding}
        {...buttonProps}
      >
        {product.inStock ? addText : 'sold out'}
      </Button>
    )
  } else if (!product.inStock) {
    return (
      <Text width="50px" textAlign="center" color="pepper-300">
        SOLD OUT
      </Text>
    )
  }

  return (
    <Flex align="center" justify="space-between">
      <ResetButton
        type="button"
        disabled={disabled}
        onClick={removeProductClickHandler}
        data-testid="product-minus-button"
      >
        <Roundel
          filled
          size={roundelSize}
          fontSize={30}
          color={theme.colors.aubergine}
        >
          <Icon type="minus" color="white" />
        </Roundel>
      </ResetButton>
      <Text textAlign="center" {...textProps} />
      <ResetButton
        disabled={disabled || disableAdding}
        type="button"
        onClick={addProductClickHandler}
        data-testid="product-plus-button"
      >
        <Roundel
          filled
          size={roundelSize}
          fontSize={30}
          color={theme.colors.broccoli}
          disabled={disableAdding}
        >
          <Icon type="plus" color="white" />
        </Roundel>
      </ResetButton>
    </Flex>
  )
}
