import {
  Button,
  ButtonProps,
  Flex,
  Icon,
  ResetButton,
  Text,
  TextProps,
  theme,
} from '@mindfulchefuk/design-system'
import { Roundel } from '@mindfulchefuk/design-system/Roundel/Roundel'
import React, { PropsWithChildren, VoidFunctionComponent } from 'react'
import { ProductWithQuantity } from '@mindfulchefuk/query'
import { PageSource } from '@mindfulchefuk/analytics'
import { UpdateProductQuantityFn } from '@mindfulchefuk/features/Products/interfaces'

type ProductCTAProps = {
  product: ProductWithQuantity
  isLarge?: boolean
  willUpdateDelivery?: boolean
  source: PageSource
  disabled?: boolean
  onAdd: UpdateProductQuantityFn
  onIncrement: UpdateProductQuantityFn
  onDecrement: UpdateProductQuantityFn
  disableAdding?: boolean
}

export const OldProductCTAs: VoidFunctionComponent<ProductCTAProps> = ({
  onAdd,
  onIncrement,
  onDecrement,
  product,
  disabled,
  isLarge,
  source,
  disableAdding = false,
  willUpdateDelivery = false,
}) => {
  const addText = isLarge ? 'Add to order' : 'Add'
  const buttonSize = isLarge ? 'medium' : 'small'
  const roundelSize = isLarge ? 48 : 32
  const buttonProps: Partial<ButtonProps> = isLarge
    ? { fullWidth: true, size: 'medium' }
    : { size: 'small' }
  const textPropChildren = willUpdateDelivery
    ? `${product.quantity} in your order`
    : `${product.quantity} in your basket`

  const textProps: PropsWithChildren<TextProps> = isLarge
    ? {
        children: textPropChildren,
        color: 'aubergine',
        variant: 'body-xl-500-caps',
        mx: 20,
      }
    : {
        children: product.quantity,
        color: 'pepper',
        variant: 'body-lg',
        width: 32,
      }

  if (product.quantity === 0) {
    return (
      <Button
        variant="primary"
        size={buttonSize}
        type="button"
        data-testid="product-add-button"
        onClick={(e) => {
          e.stopPropagation()
          onAdd({ product, quantity: product.quantity + 1, source })
        }}
        disabled={!product.inStock || disableAdding}
        {...buttonProps}
      >
        {product.inStock ? addText : 'Sold out'}
      </Button>
    )
  }

  return (
    <Flex align="center" justify="space-between">
      <ResetButton
        type="button"
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation()
          onDecrement({ product, quantity: product.quantity - 1, source })
        }}
        data-testid="product-minus-button"
      >
        <Roundel
          filled
          size={roundelSize}
          fontSize={30}
          color={theme.colors.aubergine}
        >
          <Icon type="minus" color="white" />
        </Roundel>
      </ResetButton>
      <Text textAlign="center" {...textProps} />

      <ResetButton
        disabled={disabled || disableAdding}
        type="button"
        onClick={(e) => {
          e.stopPropagation()
          onIncrement({ product, quantity: product.quantity + 1, source })
        }}
        data-testid="product-plus-button"
      >
        <Roundel
          filled
          size={roundelSize}
          fontSize={30}
          color={theme.colors.broccoli}
          disabled={disableAdding}
        >
          <Icon type="plus" color="white" />
        </Roundel>
      </ResetButton>
    </Flex>
  )
}
