import React, { VoidFunctionComponent } from 'react'

import Loader from '@mindfulchefuk/components/Loader'

import { productTypeConfig } from '@mindfulchefuk/features/Products/productTypeConfig'

import {
  ProductWithQuantity,
  ProductType,
  useQueryAllProducts,
} from '@mindfulchefuk/query'
import { UpdateProductQuantityFn } from '@mindfulchefuk/features/Products/interfaces'
import { Box, Flex, Grid, Text } from '@mindfulchefuk/design-system'
import { ProductCard } from './ProductCard'

type Props = {
  productType: ProductType
  onUpdateProductQuantity: UpdateProductQuantityFn
  onOpenDetails: (product: ProductWithQuantity) => void
  products: ProductWithQuantity[]
}

export const ProductsGrid: VoidFunctionComponent<Props> = ({
  productType,
  onUpdateProductQuantity,
  products,
  onOpenDetails,
}) => {
  const { isLoading: isFetchingProducts } = useQueryAllProducts()

  const { title, description } = productTypeConfig[productType]

  return (
    <>
      <Text
        variant="heading-lg"
        as="h1"
        color="aubergine"
        mb={20}
        data-testid="r2g-heading"
      >
        {title}
      </Text>
      <Text
        variant="body-lg"
        color="aubergine"
        mb={32}
        display={['none', 'block']}
      >
        {description}
      </Text>
      {isFetchingProducts ? (
        <Flex
          align="center"
          justify="center"
          width="100%"
          height="50vh"
          maxHeight="100%"
          data-testid="product-list-loading-container"
        >
          <Loader />
        </Flex>
      ) : (
        <Grid
          as="ul"
          templateColumns={{
            base: 'repeat(2, minmax(135px, 1fr))',
            md: 'repeat(auto-fit, minmax(260px, 1fr))',
          }}
          gap={{ base: 8, lg: 24 }}
          pb={[48, 96]}
        >
          {products.map((product) => (
            <Box as="li" key={product.id}>
              <ProductCard
                product={product}
                onOpenDetails={onOpenDetails}
                onAdd={onUpdateProductQuantity}
                onIncrement={onUpdateProductQuantity}
                onDecrement={onUpdateProductQuantity}
              />
            </Box>
          ))}
        </Grid>
      )}
    </>
  )
}
