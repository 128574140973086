import { useCallback } from 'react'
import {
  LineItem,
  Products,
  ProductType,
  ProductWithQuantity,
  transformProductWithQuantity,
  useQueryAllProducts,
} from '@mindfulchefuk/query'

interface Props {
  type: ProductType
  lineItems: LineItem[]
}

export const useGetShopProductsWithQuantities = ({
  lineItems,
  type,
}: Props) => {
  const selectAllProductsWithQuantities = useCallback(
    (products: Products): ProductWithQuantity[] => {
      const shopProducts = products.filter(
        (product) => product.status === 'published'
      )
      return transformProductWithQuantity(shopProducts, type, lineItems)
    },
    [lineItems, type]
  )

  return useQueryAllProducts({ select: selectAllProductsWithQuantities })
}
