import styled from '@emotion/styled'
import { Image, theme } from '@mindfulchefuk/design-system'
import { motion } from 'framer-motion'

export const ProductNavigationList = styled.ul`
  list-style: none;
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 0;

  overflow: hidden;
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  ${theme.media.lg} {
    overflow-x: hidden;
    justify-content: space-between;
  }
`

export const ProductNavigationListItem = styled(motion.li)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 150px;
  flex-shrink: 0;
  min-width: 120px;
  margin-right: ${theme.space[16]};
  scroll-snap-align: start;

  &:last-child {
    /* Padding here means the slider track has the correct left hand */
    /* spacing when the items are fully moved to the left */
    margin-right: 0;
  }

  ${theme.media.lg} {
    flex-shrink: 1;
    margin-right: ${theme.space[12]};
    min-width: 0;

    &:last-child {
      /* No overflow means margin is calculated automatically */
      /* and we no longer need to track padding as there is no slider */
      margin-right: 0;
      padding-right: 0;
    }
  }
`

export const ProductNavigationImage = styled(Image)`
  width: 165px;
  height: auto;

  ${theme.media.lg} {
    width: 100%;
  }
`

export const ProductNavigationActiveMarker = styled(motion.div)`
  background-color: ${theme.colors.aubergine};
  border-radius: 4px;
  height: 2px;
  inset: auto 0 0;
  position: absolute;
`
